import React from 'react';
import { FormattedMessage } from 'react-intl';
import Link from '/src/components/LocalizedLink';
import Faq from '../../components/Faq';
import IconArrowRightRed from '../../assets/images/svg/arrow-right-red.inline.svg';
import Layout from '../../components/Layout';

import { URL_KEYS_ROUTES_EN } from '../../constants/routes';

import IconArrowRight from '../../assets/images/svg/arrow-right.inline.svg';
import imgPhoto1 from '../../assets/images/student-visas/photo 1 (5).png';
import imgPhoto2 from '../../assets/images/student-visas/Photo 2 (5).png';
import imgPhoto3 from '../../assets/images/student-visas/Photo 3 (3).png';
import imgPhoto4 from '../../assets/images/student-visas/Photo 4 (1).png';
import imgDots from '../../assets/images/student-visas/Dots (1).svg';
import imgReview from '../../assets/images/trinity-cert-tesol-course-page/Review photo.jpg';
import iconQuotes from '../../assets/images/trinity-cert-tesol-course-page/quotes.svg';

// import img1 from '../../assets/images/student-visas/Component 81 – 9.svg';
// import img2 from '../../assets/images/student-visas/Component 81 – 9 (1).svg';
// import img3 from '../../assets/images/student-visas/Component 81 – 9 (2).svg';
import imgBeforeSet from '../../assets/images/student-visas/Before you set off.svg';
import imgOnceInBarcelona from '../../assets/images/student-visas/Once you are in Barcelona.svg';
import iconTick from '../../assets/images/svg/tick.svg';

import fileApplicationStudentVisa from '../../assets/files/Spanish-student-visa-application-form.pdf';

import '../../assets/styles/pages/student-visas-page.scss';

const option1 = `
<p>Ideal if you only plan to be in Barcelona for 3-6 months. You cannot extend this visa from Spain.</p>

`;
const option2 = `
<p>Ideal if you plan to be in Barcelona for more than 6 months. This visa also allows you to work, and extend from within Spain.</p>
<br/>
<p>You must sign up to a course that is 20 hours/week and you can apply from both your home country and in Spain.</p>
`;

const package1 = `
<p>This programme is for you if you want to continue to work on your professional development as a teacher. The package includes:
</p>
<br/>
<ul class='faq-list'>
    <li class='faq-list__item'>12-month programme</li>
    <li class='faq-list__item'>20 hours/week Developing Teacher course</li>
    <li class='faq-list__item'>Support from our experienced visa coordinator</li>
    <li class='faq-list__item'>Assistance in gathering all your documents, applying for the visa and bureaucracy once you arrive in Barcelona.
    </li>
    <li class='faq-list__item'><b>Total: 1500€</b> (Save €300!)</li>
</ul><br/>
<p>If you do not want to do 12 months, this course is priced at 150€/month.
All courses must be paid in full in order to apply for the student visa.
</p>
`;

const package2 = `
<p>This programme is for you if you want to get qualified, continue to work on your professional development as a teacher and achieve fluency in Spanish with 10 hours/week of lessons! The package includes:</p>
<br/>
<ul class='faq-list'>
    <li class='faq-list__item'>12-month programme</li>
    <li class='faq-list__item'>10 hours/week of Spanish lessons from professional, qualified teachers </li>
    <li class='faq-list__item'>10 hours/week of Developing Teacher course</li>
    <li class='faq-list__item'>Support from our experienced visa coordinator
    </li>
    <li class='faq-list__item'>Assistance in gathering all your documents, applying for the visa and bureaucracy once you arrive in Barcelona.</li>
    <li class='faq-list__item'><b>Total: 3000€ </b> (Save €300!)</li>
</ul><br/>
<p>If you do not want to do 12 months, this course is priced at 275€/month.
All courses must be paid in full in order to apply for the student visa.
</p>
`;

const StudentVisas = () => {
  return (
    <Layout
      title={'Get a Spanish Student Visa with a Language Course | 2024'}
      description={
        'Our 20 hours/week intensive Spanish course allow you to live and work in Spain for 12+ months while you study. Find out more and apply online.'
      }
    >
      <div className="student-visas-page">
        <section className="c-first-section c-first-section--md">
          <div className="container">
            <h1 className="title">Spanish Courses For Long-Term Student Visas</h1>
            <p className="description">
              Our student visa programmes allow you to live and work in Spain whilst you study
              Spanish.
            </p>
          </div>
        </section>
        <section className="second-section c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="block_l">
                  <h2 className="c-title-34 block_l__title">
                    Getting A Student Visa in Spain Has Never Been Easier
                  </h2>
                  <p className="c-text-18 block_l__description">
                    Studying Spanish is a sure-fire way to qualify for a long-term student visa,
                    allowing you to live and work in Spain for 12+ months. At Iberia Language
                    Academy we have years of experience in helping non-EU citizens apply for and
                    obtain their long-term visa, with a very high success rate. Our 20 hours/week
                    intensive Spanish course meets all the requirements and we can provide you with
                    all the necessary official documents you’ll need.
                  </p>
                  <Link to={'/brexit-guide'} className="c-btn c-btn--red-border">
                    Download our free visa guide
                    <IconArrowRightRed />
                  </Link>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="block_r">
                  <img src={imgPhoto1} alt="imgPhoto1" className="block_r__img" />
                  <img src={imgDots} alt="imgDots" className="dots" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="third-section c-section-includes c-faqs-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="block_l">
                  <div className="block_l__img">
                    <img src={imgPhoto2} alt="" className="img-main" />
                  </div>
                  <div className="block_l__img-c">
                    <img src={imgPhoto3} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="block_r">
                  {/* <h2 className="c-title-34">
                    You Have Two Options For Processing A Student Visa:
                  </h2> */}
                  <h3 className="c-title-24">
                    You Have Two Options For Processing A Student Visa:
                  </h3>
                  {/* <div className="block_r__item">
                    <img src={img1} alt="" className="number" />
                    <div className="item__text">
                      <h4 className="item__title">Trinity CertTESOL + Developing Teacher course</h4>
                      <p className="item__description">
                        Get qualified then continue to work on your professional development as a
                        teacher.
                      </p>
                    </div>
                  </div> */}
                  <Faq
                    title="Less than 180 days (short-term visa)                    "
                    description={option1}
                  />
                  {/* <div className="block_r__item">
                    <img src={img2} alt="" className="number" />
                    <div className="item__text">
                      <h4 className="item__title">
                        Trinity CertTESOL + Spanish lessons & Developing Teacher combination
                      </h4>
                      <p className="item__description">
                        Get qualified and learn Spanish alongside professional teacher development.
                        Achieve fluency in Spanish with 10 hours/week of lessons!
                      </p>
                    </div>
                  </div> */}
                  <Faq title="More than 180 days (long-term visa)" description={option2} />
                  {/* <div className="block_r__item">
                    <img src={img3} alt="" className="number" />
                    <div className="item__text">
                      <h4 className="item__title">Trinity CertTESOL course</h4>
                      <p className="item__description">
                        + combination of Spanish & Developing Teacher course
                      </p>
                    </div>
                  </div> */}

                  <div className="block_r__price block_r__price--early-bird">
                    <h3 className="c-text-13">
                      Spanish lessons:
                      <span className="notion">20 hours/week</span>
                    </h3>
                    <h3 className="c-title-32">
                      3900€/ <span className="lowercase">/year</span>
                    </h3>
                  </div>
                  <div className="block_r__btns">
                    <FormattedMessage id={URL_KEYS_ROUTES_EN.APPLY}>
                      {(locale) => (
                        <Link to={locale[0]} className="c-btn c-btn--red">
                          Book Now
                          <IconArrowRight />
                        </Link>
                      )}
                    </FormattedMessage>
                    <a
                      target="_blank"
                      href={'https://form.jotform.com/231496158857369'}
                      rel="noopener noreferrer"
                      className="c-btn c-btn--red-border"
                    >
                      Test Your Spanish
                      <IconArrowRightRed />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="fourth-section c-section-py">
          <div className="container">
            <div className="row second-row">
              <div className="col-md-6 col-12">
                <div className="block_l">
                  <div className="block_l__img">
                    <img src={imgPhoto4} alt="What documents do I need?" />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="block_r">
                  <h3 className="block_r__title">What documents do I need?</h3>
                  <p className="block_r__subtitle">
                    The Spanish consulate requires the following documents:
                  </p>
                  <div className="c-benefit-list">
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Passport, valid for 6 months beyond your stay in Spain
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Completed application form for Spanish student visa (
                        <a
                          target="_blank"
                          href={fileApplicationStudentVisa}
                          download
                          className="red-link"
                        >
                          downloadable pdf
                        </a>
                        )
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Enrolment letter from the school.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Academic program and Cervantes accreditation.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Criminal background check with apostille certificate.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Proof of course payment in full.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Medical certificate.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Medical insurance with full coverage.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Proof of sufficient funds to support yourself during your stay (600€/month,
                        or 7200€ for 1 year)
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Proof of address in your home country.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Proof of accommodation in Barcelona (we can help you with this).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="fourth-section fourth-section--preview c-section-py">
          <div className="container">
            <h2 className="fourth-section__title c-title-34">Why Use the ILA Visa Service?</h2>
            <p className="fourth-section__description c-text-18">
              Our visa service stands heads and shoulders above the rest because we go the extra
              mile:
            </p>
            <div className="row first-row">
              <div className="col-md-6 col-12">
                <div className="block_l">
                  <img src={imgBeforeSet} alt="imgOnceInBarcelona" className="block_l__img" />
                  <h3 className="block_l__title">Before you leave:</h3>
                  <div className="c-benefit-list">
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Detailed, country-specific guidance on how to gather and complete all the
                        necessary paperwork.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Help completing the Spanish visa application form.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Special discount on health insurance for ILA students.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Fast response from coordinators with years of experience in processing
                        student visas.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Fast delivery of your enrolment letter.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        96% success rate in processing student visas and minimal rejections.
                      </p>
                    </div>
                  </div>
                  {/* <p className="block_r__subtitle">
                    <b>150€</b> per month or <b>1500€</b> for 1 year
                  </p> */}
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="block_r">
                  <img src={imgOnceInBarcelona} alt="imgOnceInBarcelona" className="block_r__img" />
                  <h3 className="block_r__title">Once you arrive in Barcelona:</h3>
                  <div className="c-benefit-list">
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        We'll take care of scheduling your TIE (Tarjeta de identidad de extranjero)
                        appointment for you, which can be quite challenging to secure. This will
                        save you a significant amount of time and trouble.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        In addition, a representative from the school will accompany you to the
                        foreign office to assist with paperwork and translations.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        You'll receive continuous guidance from the school on obtaining all the
                        necessary documents to obtain your student card (TIE) and registering your
                        address (empadronamiento).
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16 text-fw-600">
                        Please note that there's an extra fee of 200€ for booking appointments for
                        the student card and housing register, as well as providing accompaniment.
                      </p>
                    </div>
                  </div>{' '}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="fifth-section c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="block_l">
                  <h2 className="block_l__title c-title-34">
                    Still Not Sure? <span className="colored no-link">Contact Us Anytime</span>
                  </h2>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="block_r">
                  <p className="block_r__par c-text-18">
                    If you have any questions or would like to get more detailed information, feel
                    free to reach out to{' '}
                    <a href="mailto:classes@ilabarcelona.com" className="colored">
                      classes@ilabarcelona.com
                    </a>{' '}
                    You can either{' '}
                    <a target="_blank" href="http://calendly.com/" className="colored">
                      book an appointment
                    </a>{' '}
                    to speak in person at the school or schedule a video call.
                  </p>
                  {/* <p className="block_r__par c-text-18">
                    If you would like to read about recent, first-hand experiences we highly
                    recommend joining the{' '}
                    <a
                      target="_blank"
                      href="https://www.facebook.com/groups/2229995425"
                      className="colored text-fw-600"
                    >
                      Barcelona TEFL Teachers’ Association page
                    </a>{' '}
                    or{' '}
                    <a
                      target="_blank"
                      href="https://www.facebook.com/groups/41751319472/"
                      className="colored text-fw-600"
                    >
                      TEFL Teachers in Seville page
                    </a>{' '}
                    on Facebook. They are very active online communities with tons of non-EU
                    teachers and TEFL trainees sharing their experiences. If you use their search
                    function on the top right-hand corner of the page to search, for example,
                    ‘student visa’, you’ll find lots of active discussions.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="c-faqs-section">
          <div className="container">
            <div className="c-faqs-section__title">
              <h2 className="c-title-34">Frequently Asked Questions</h2>
              {/* <Link to="/courses/faq" className="c-btn c-btn--white">
                View More FAQs
                <IconArrowRightRed />
              </Link> */}
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Faq
                  title="Do I need a student visa to study in Spain?"
                  description="If you're a non-European Union citizen planning to study in Spain for more than 90 days, you'll need to apply for a student visa."
                />
                <Faq
                  title="How do I apply for a student visa?"
                  description="You can apply for a student visa through the Spanish consulate or embassy in your home country, or within your first 30 days in Spain. You'll need to provide all the documents listed above."
                />
                <Faq
                  title="Can I work while on a student visa?"
                  description="Yes, you're allowed to work part-time (up to 20 hours per week) while on a student visa."
                />
                <Faq
                  title="Can I apply for a student visa if I'm already in Spain?"
                  description="Yes, as long as you apply for the visa within your first 30 days in Spain."
                />
                <Faq
                  title="How long does it take to process a student visa application?"
                  description="The processing time for a student visa application can vary depending on the consulate or embassy processing your application. Generally, it can take from a few weeks to 3 months to receive a decision on your application."
                />
              </div>
              <div className="col-lg-6">
                <Faq
                  title="Can I apply for the visa on my own in Spain, without the help of a lawyer?"
                  description="Yes, you will have to book an appointment at the Extranjería (Immigration Office) and submit all your documents there.
<br/>
<br/>
When applying on your own it is difficult to get updated information on your application status, as the office only issues notifications via letters, and there are often problems receiving them. So you might not be able to respond on time, and in this case you risk being denied a visa. For this reason we recommend using a lawyer if you decide to apply from within Spain.
                  "
                />
                <Faq
                  title="Can I get a refund if I change my mind?"
                  description="We will only issue refunds if you receive a letter of rejection from the immigration office."
                />
                <Faq
                  title="What happens if my visa is rejected?"
                  description="We will study the reason for rejection and help you decide what to do. If it is an easy fix, such as missing paperwork you can appeal and have it approved. We will also reschedule your course until your visa is approved. If it is not possible to appeal, we will give you a full refund, minus a 300€ enrolment fee."
                />
                {/* <Faq
                  title="How easy is it to find private classes in Spain?"
                  description="Very easy, we show you exactly how to do it on the TEFL course."
                /> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default StudentVisas;
